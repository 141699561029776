@import 'base.sass';
html {
    body {
        .contenedor {
            header {
                // Cabeceras Internas
                .banner-internas-img {
                    display: block;
                    width: 100%;
                    .banner-internas__img {
                        display: block;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                        min-height: size(135);
                        max-height: size(135);
                        @media (min-width: $breakpoint1) {
                            min-height: size(299);
                            max-height: size(299); } } } }
            .contenido {
                .error-404 {
                    margin: 50px 0px;
                    .titulos {
                        font-family: $fuente;
                        font-weight: 400;
                        font-size: size(30);
                        color: $color1;
                        line-height: size(36);
                        text-align: center;
                        @media (min-width: $breakpoint1) {
                            font-size: size(40); } } } } } } }
